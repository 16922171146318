@import "upstairs/static_src/styles/_placeholders";

.block-image {
  padding: 2rem 1rem;
  @extend %container;

  &__caption {
    font-size: .85rem;
  }

  &__attribution {
    font-size: .8rem;
  }
}

.img-block--bw {
  .lazy.loaded {
    filter: grayscale(100%) !important;
  }
}

.img-block--sepia {
  .lazy.loaded {
    filter: sepia(100%);
  }
}
