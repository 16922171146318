.zoomer {
  margin-bottom: 50px;

  .carousel-item {
    overflow: hidden;
  }

  .carousel-indicators {
    margin-bottom: 0;
    bottom: -55px;
    max-width: 70%;

    li {
      width: 100px;
      height: 50px;
      text-indent: unset;
      border-top: 0;
      border-bottom: 0;
      background: none;
    }
  }
}
