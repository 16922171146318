@import "upstairs/static_src/styles/_variables";
@import "upstairs/static_src/styles/_placeholders";
@import "upstairs/static_src/styles/_mixins";

.block-carousel {
  $carouselHeight: 75vh;
  max-height: $carouselHeight;
  width: auto;
  max-width: 2000px;
  margin: auto;

  @include mediaQuery($max, $lg) {
    max-height: none;
  }

  .carousel__img {
    max-height: calc(75vh - 2rem);
    width: auto;
    object-fit: cover;

    @include mediaQuery($max, $lg) {
      max-height: none;
    }
  }
}

.carousel {
  @include mediaQuery($max, $lg) {
    background: $black;
  }
}

// ============================
// Carousel Caption
// ============================

.carousel-caption {
  left: 0;
  max-width: 50vw;

  @include mediaQuery($max, $lg) {
    max-width: 100%;
    position: relative;
    padding-top: 2rem;

    p {
      font-size: .9rem;
      line-height: 1.3;
    }
  }

  .display-4,
  p {
    text-shadow: 0 .1rem .2rem rgba(0,0,0,.5);

    @include mediaQuery($max, $lg) {
      text-shadow: none;
    }
  }
}

// ============================
// Indicators
// ============================

.carousel-indicators {
  left: 0;
  right: auto;
  bottom: .5rem;
}

// ============================
// Arrows
// ============================

.carousel__arrow {
  background: $white;
  color: fade-out($black, .25);
  border-radius: .25rem;
  width: 35px;
  height: 35px;
  opacity: 1;
  top: auto;
  bottom: 2rem;
  right: 2rem;
  font-size: .7rem;
  margin: 0 .5rem;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  border: 1px solid $white;
  transition: color .25s, box-shadow .25s, border-color .25s;

  @include mediaQuery($max, $md) {
    bottom: 1rem;
  }

  &.carousel-control-prev {
    right: calc(40px + 2rem);
    left: auto;
  }

  &:hover,
  &:focus {
    color: $black;
    background: $white;
    opacity: 1;
    border-color: $brand;
    box-shadow: 0 .25rem .5rem rgba(0,0,0,.35);
  }
}
