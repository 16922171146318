@import "upstairs/static_src/styles/_placeholders";

.block-embed {
  padding: 2rem 1rem;
  @extend %container;

  iframe {
    width: 100%;
    height: 65vh;
  }
}

.block-grid {
  iframe {
    width: 100%;
    height: 65vh;
  }
}
