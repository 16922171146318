.block-hotspot {
  max-width: 1140px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.hotspot {
  margin-bottom: 2rem;
  max-width: 100%;
  max-height: 90vh;
  position: relative;
  display: flex;
  justify-content: center;

  &__buttons {
    display: inline-block;
    position: relative;
  }

  &__image {
    width: auto;
    max-width: 100%;
    max-height: 90vh;
    height: auto;
  }

  &__content {
    width: 300px;
    height: auto;
    color: black;
    background-color: rgb(255, 255, 255);
    display: none;
    position: absolute;
    border-radius: 0.25rem;
    z-index: 10;

    @media (max-width: 1023px) {
      left: 0 !important;
      width: calc(100% - 1rem);
      margin: .5rem;
    }
  }

  &__contentimage {
    float: right;
  }

  &__title {
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  &__link {
    display: block;
    font-size: 0.9em;
    margin-top: 0.5rem;
  }

  &__item {
    position: absolute;
    $size: 1.25rem;
    width: $size;
    height: $size;
    margin-left: -$size / 2;
    margin-right: -$size / 2;
    border-radius: 50%;
    background: #ff69ff;
    background: rgb(255, 182, 255);
    background: radial-gradient(
      circle,
      rgba(255, 182, 255, 1) 0%,
      rgba(255, 157, 255, 1) 20%,
      rgba(255, 105, 255, 1) 69%
    );
    cursor: pointer;
    box-shadow: 0 0 0 rgba(255, 105, 255, 0.6);
    animation: pulse 2s infinite;
    border: none;

    &:hover {
      animation: none;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 105, 255, 0.6);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(255, 105, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 105, 255, 0);
    }
  }
}
