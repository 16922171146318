.block-blockquote {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem 1rem;

  blockquote,
  blockquote p {
    font-size: 2rem;
    line-height: 2rem;
    font-style: italic;
  }

  .blockquote-footer {
    text-align: right;
    font-size: 1rem;

    small {
      text-transform: uppercase;
    }
  }
}
