.block-hero {
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;

  picture {
    text-align: center;
  }

  img {
    max-height: 65vh;
    min-width: 100%;
    width: auto;
    object-fit: cover;
    object-position: center;
    margin-left: auto;
    margin-right: auto;
  }
}
